.resources-list {
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card-resource {
        .none {
            display: none;
        }

        &:hover {
            .none {
                display: block;
            }
        }
    }
}
