.custom-tab {
    width: 450px;
    // height: calc(100% + 48px);
    height: 100%;
    color: #52636c;
    -webkit-box-shadow: -6px 0px 6px -5px rgba(0, 0, 0, 0.41);
    box-shadow: -6px 0px 6px -7px rgba(0, 0, 0, 0.41);
    // margin-top: -48px;

    .header {
        background-color: #363f4e;
        color: #8ca0b3;

        bds-typo {
            color: #8ca0b3;
        }

        .bds-tabs__header {
            display: flex;
            width: fit-content;

            bds-typo {
                color: #8ca0b3;
            }
        }
    }
}

.bds-tab:hover {
    bds-typo {
        color: #3f7de8;
    }
}

.tab_group__header {
    display: none !important;
    background-color: red !important;
}

.custom-tab .header .bds-tabs__header {
    flex-direction: space-between !important;
}

.bds-tab:not(:last-child) {
    margin-right: 0px !important;
}
