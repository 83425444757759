.fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.drag-area {
    border: 2px dashed #D5E8FF;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
}
