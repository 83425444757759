.items-list {
    display: flex;
    flex-direction: column;
    flex: 1;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .items {
        display: flex;
        flex-direction: column;
        gap: 10px;

        table {
            width: 100%;

            tbody {
                tr {
                    td {
                        border-top: 1px solid #ccc;
                        padding: 5px;
                    }
                }
            }
        }

        .card-resource {
            .none {
                visibility: hidden;
            }

            &:hover {
                .none {
                    visibility: visible;
                }
            }
        }
    }

    .single {
        table {
            width: 100%;

            tbody {
                tr:not(:first-child) {
                    td,
                    th {
                        border-top: 1px solid #ccc;
                        padding: 5px;
                    }
                }
            }
        }

        .card-resource {
            .none {
                visibility: hidden;
            }

            &:hover {
                .none {
                    visibility: visible;
                }
            }
        }
    }

    .card-swagger {
        flex: 1;

        div {
            height: 100%;
            display: flex;
            flex-direction: column;

            iframe {
                display: block;
                flex: 1;
                border: 0;
                width: 100%;
            }
        }
    }
}
