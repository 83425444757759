.resource-field {
    border: 2px dashed #ccc;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;

    .flutuant-bottons {
        position: absolute;
        top: -60px;
        right: 10px;
        display: none;
        padding: 10px;
        background-color: #ccc;
        border-radius: 10px 10px 0 0;
    }

    &:hover > .flutuant-bottons {
        display: flex;
        gap: 5px;
    }

    .options {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
