.modal-resources {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 40vw;
    max-height: 80vh;

    .modal-body {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        z-index: 1;

        .fields {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
}
