.fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.object {
    position: relative;
    
    .flutuant-bottons {
        position: absolute;
        top: -60px;
        right: 10px;
        display: none;
        padding: 10px;
        background-color: #ccc;
        border-radius: 10px 10px 0 0;
    }

    &:hover > .flutuant-bottons {
        display: block;
    }
}
