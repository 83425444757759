.bucketFile {
    display: flex;
    gap: 10px;

    & > div:first-child {
        flex: 1;
    }

    .button--size-standard {
        height: 55px !important;
        // background-color: red;
    }
}